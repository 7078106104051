import 'bootstrap/dist/css/bootstrap.min.css';


function Navbar() {
  return (
    <div>
      <div className="App">
        <div className="container">
          <header className="App-header">
            <img src="https://cdn.flixbus.de/assets/kamil-20200907/kamilkoc-collaboration-logo.svg" className="App-logo" alt="logo" />
          </header>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
