import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

const recaptchaRef = React.createRef();
var IBAN = require('iban');

// const URL = "https://api.kamilkoc.com.tr/FBTEST/SaveCancelledTicketRefundForm";
const URL = "https://api.kamilkoc.com.tr/FB/SaveCancelledTicketRefundForm";

class App extends Component {
    state = {
        PNR: "",
        AdSoyad: "",
        IBAN: "TR",
        Telefon: "",
        Captcha: "",
        IBANStatus: false
    }


    componentDidMount = () => {
        const queryString = window.location.search;
        var pnr = queryString.replace('?PNR=', '');
        if (!!queryString) {
            const urlParams = new URLSearchParams(queryString);
            this.setState({
                ...this.state,
                PNR: pnr
            });
        }
    }

    inputChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    inputChangeIBAN = (e) => {
        this.setState({
            ...this.state,
            IBAN: IBAN.printFormat(e.target.value, ' ')
        });

    }




    captchaChange = (e) => {
        const recaptchaValue = recaptchaRef.current.getValue();
        this.setState({
            ...this.state,
            Captcha: recaptchaValue
        });
    }

    handleSubmit = () => {
        if (!(!!this.state.PNR && !!this.state.AdSoyad && !!this.state.Telefon && !!this.state.IBAN && !!this.state.Captcha)) {
            alert("Lütfen tüm alanları doldurun ve Güvenlik Kontrolünü tamamlayın.")
            return;
        }
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        var params = {
            PNR: this.state.PNR,
            PhoneNumber: this.state.Telefon,
            IBAN: this.state.IBAN.replace(/ /g, ''),
            NameSurName: this.state.AdSoyad,
            CaptchaKey: this.state.Captcha
        };

        axios.post(URL, params, { headers: headers }).then(res => {
            if (res.data.ResponseCode === 200) {
                // this.setState({
                //     ...this.state,
                //     Biletler: res.data.Nesneler
                // });
                alert('Kaydınız başarılı bir şekilde oluşturulmuştur.');
            }
            else {
                alert(res.data.ResponseMessage)
            }
            recaptchaRef.current.reset();
        });
    }




    render() {
        return (
            <div className="container">
                <br></br>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <label>PNR</label>
                    </div>
                    <div className="col-md-4"></div>
                </div>

                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <input type="text" id="PNR" className="w100" value={this.state.PNR} name="PNR" disabled />
                    </div>
                    <div className="col-md-4"></div>
                </div>

                <div className="row mt10">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <label>IBAN Sahibinin Adı Soyadı</label>
                    </div>
                    <div className="col-md-4"></div>
                </div>


                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <input type="text" id="AdSoyad" className="w100" name="AdSoyad" placeholder="Ad Soyad" onChange={this.inputChange} />
                    </div>
                    <div className="col-md-4"></div>
                </div>


                <div className="row mt10">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <label>IBAN Numarası</label>
                    </div>
                    <div className="col-md-4"></div>
                </div>


                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 block">
                        <input type="text" id="IBAN" maxLength={32} className="w100" value={this.state.IBAN} name="IBAN" placeholder="TRXX XXXX XXXX XXXX XXXX" onChange={this.inputChangeIBAN} />
                        {(this.state.IBAN.length >= 28 && !IBAN.isValid(this.state.IBAN) || (this.state.IBAN.length >= 2 && !this.state.IBAN.startsWith('TR'))) && <label className="clrRed">IBAN bilginiz hatalıdır.</label>}
                        {this.state.IBAN.length < 3 && <label className="clrRed">Lütfen IBAN giriniz.</label>}
                    </div>
                    <div className="col-md-4">
                    </div>
                </div>


                <div className="row mt10">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <label>Telefon</label>
                    </div>
                    <div className="col-md-4"></div>
                </div>


                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 flex">
                        <input type="text" id="Telefon" className="w100 pl40" name="Telefon" placeholder="5XXXXXXXXX" onChange={(e) => {
                            if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                            }
                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            this.inputChange(e);
                        }} />  <span className="absolute ml5">+90</span>
                    </div>
                    <div className="col-md-4"></div>
                </div>



                <div className="row mt10">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <label>Güvenlik</label>
                    </div>
                    <div className="col-md-4"></div>
                </div>


                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <ReCAPTCHA
                            name="Captcha"
                            className="w100"
                            ref={recaptchaRef}
                            sitekey="6LekrhcUAAAAAK4lRSuLdEztRQsf-RW8V1kuRwEd"
                            onChange={this.captchaChange}
                        />
                    </div>
                    <div className="col-md-4"></div>
                </div>

                <div className="row mt10">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <input type="button" onClick={this.handleSubmit} className="sendButton" disabled={IBAN.isValid(this.state.IBAN) ? false : true} value="G Ö N D E R     >" />
                    </div>
                    <div className="col-md-4"></div>
                </div>




            </div>
        );
    }
}

export default App;